import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useResizeDetector } from 'react-resize-detector';
import Box from '@mui/material/Box';
import HC_more from 'highcharts/modules/annotations';
import useDebounce from 'hooks/useDebounce';
import ReactDOMServer from 'react-dom/server';

HC_more(Highcharts);

const OutcomeChart = ({
                        legend,
                        tippingPoint,
                        populationFractionToLeft = 0,
                        yLabel,
                        xLabel,
                        data = [],
                        color = '#000000',
                        segment,
  elasticity=0
                      }) => {
  const { width, ref } = useResizeDetector();
  const chartWidth = useDebounce(width, 200);

  let html = ReactDOMServer.renderToString(<div>
    <div style={{ paddingLeft: '1em', maxWidth: '400px', minWidth: '300px' }}>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{
          display: 'flex',
          paddingRight: '0.5em',
          width: 'fit-content',
          alignItems: 'center',
          height: '1.25rem',
        }}><span style={{
          backgroundColor: '#ff7000',
          width: '0.875em',
          height: '0.875em',
          borderRadius: '100%',
          cursor: 'pointer',
        }}></span>
        </div>
        <span
          style={{
            fontWeight: 700,
            fontSize: '1.25rem',
          }}>{(tippingPoint?.x).toFixed(2)}</span>
        <span style={{
          paddingLeft: '0.5em',
          paddingBottom: '0.25em',
          fontWeight: 700,
          fontSize: '0.875rem',
        }}>Tipping Point</span>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <span
        style={{ fontSize: '0.875rem', whiteSpace: 'normal',paddingLeft: '1em',lineHeight:1.25 }}>
        When {xLabel} for {segment} hits {(tippingPoint?.x).toFixed(3)} the population weighted {yLabel} rate difference between left and right of the tipping point is {(elasticity).toFixed(3)}
      </span>
      </div>

    </div>
  </div>);

  const charOptions = () => {
    return {
      chart: {
        height: '320px',
        animation: false,
        spacingBottom: 0,
        type: 'line',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      xAxis: {
        gridLineWidth: 0,
        labels: {
          style: { fontSize: '0.875rem', fontFamily: 'inherit' },
        },
      },
      yAxis: {
        labels: {
          style: { fontSize: '0.875rem', fontFamily: 'inherit' },
        },
        gridLineWidth: 0,
      },
      tooltip: {
        backgroundColor: '#ffffff',
        borderColor: '#e5e5e5',
        outside: true
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false,
          },
        },
      },
    };
  };

  const [options, setOptions] = useState(charOptions());
  const chartRef = useRef(null);

  useEffect(() => {
      const yValues = data.map(value => {
        return value[1];
      });
      const xValues = data.map(value => {
        return value[0];
      });
      setOptions({
        ...charOptions(),
        yAxis: {
          title: {
            text: yLabel,
            style: { fontSize: '0.875rem', fontFamily: 'inherit' },
          },
          height: '70%',
          top: '30%',
          formatter: function() {
            return this.value;
          },
          enabled: true,
          // plotBands: [
          //   {
          //     color: '#f9f9f9',
          //     from: Math.min(...yValues),
          //     to: Math.max(...yValues),
          //     label: {
          //       text: `${yLabel} changes between ${Math.min(...yValues).toFixed(3)} and ${Math.max(...yValues).toFixed(3)}`,
          //       align: 'left',
          //       y: Math.max(...yValues),
          //       verticalAlign:'top',
          //       useHTML:true,
          //       paddingTop:'32px'
          //     },
          //   },
          //
          // ],
          // plotLines: [
          //   {
          //   color: '#6690a1',
          //   width: 1,
          //   value: Math.min(...yValues),
          //   zIndex: 5,
          //   dashStyle: 'Dash',
          // },
          //   {
          //     color: '#6690a1',
          //     width: 1,
          //     value: Math.max(...yValues),
          //     zIndex: 5,
          //     dashStyle: 'Dash',
          //   }
          // ],
        },
        xAxis: {
          title: {
            text: xLabel,
            style: { fontSize: '0.875rem', fontFamily: 'inherit' },
          },
          gridLineWidth: 0,
        },
        annotations: [
          {
            labelOptions: {
              backgroundColor: 'rgba(255,255,255,0.5)',
              verticalAlign: 'top',
              align: 'left',
              useHTML: true,
              shape: 'rect',
              draggable: '',
              allowOverlap: true,
              formatter: function() {
                return html;
              },
            },
            labels: [{
              align: 'left',
              borderWidth: 0,
              point: {
                xAxis: 0,
                x: xValues[0],
                y: 1,
              },
            },
            ],
            zIndex:0
          },
        ],
        series: [
          {
            name: legend,
            data: data,
            color: '#00be2d',
            marker: {
              enabled: true,
            },
            showInLegend: false,
            tooltip: {
              headerFormat: '',
              useHTML: true,

              pointFormatter: function() {
                return `<span style='font-size: 0.875rem; width:100%; line-height: 16px;padding:16px;z-index: 9999!important; background-color: red'>
                           <span style='color:#ffffff'>.</span>${xLabel}: ${isNaN(this.x) ? this.x : (this.x).toFixed(3)}<br>
                           ${yLabel}: ${isNaN(this.y) ? this.y : (this.y).toFixed(3)}</span>`;
              },
            },
          },
          {
            name: 'Tipping Point',
            data: [[tippingPoint.x, tippingPoint.y]],
            lineWidth: 0,
            marker: {
              enabled: true,
              radius: 6,
              fillColor: '#ff7000',
              symbol: 'circle'
            },
            tooltip: {
              headerFormat: '',
              useHTML: true,
              pointFormatter: function() {
                return `<p style='font-size: 0.875rem'><span style='font-weight: bold'>Tipping Point: ${isNaN(this.x) ? this.x : (this.x).toFixed(3)}</span><br>Population fraction to the left: ${(populationFractionToLeft * 100).toFixed(3)}%</p>`;
              },
            },
            states: {
              hover: {
                lineWidthPlus: 0,
              },
            },
            showInLegend: false,
          },
        ],
      });
    },
    [data, xLabel, yLabel, legend],
  );

  useEffect(() => {
    if (chartRef) {
      chartRef.current.chart.reflow();
    }

  }, [chartWidth]);

  return (<Box ref={ref}>
      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
    </Box>
  );
};

export default OutcomeChart;
