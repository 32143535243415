import React from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

const CardRoot = styled(Box)(() => ({
  padding: '8px 16px',
  border: '0.125em solid #E9E9E9',
  borderRadius:"4px",
  marginBottom: '8px',
  boxShadow: 'none',
  '&$expanded': {
    margin: '0px',
  },
  cursor:'pointer',
}));

const FeatureItem = ({ name, value,width=6 }) => {
  return <Grid item xs={width} container>
    <Grid item xs={12}>
      <Typography sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize:'0.875rem',
        fontWeight:500,
        color: 'grey',
      }}>{name}</Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography sx={{
        fontSize:'0.875rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}>{value}</Typography>
    </Grid>
  </Grid>;
};

const Feature = ({
                   item, key, selected = false,onClick
                 }) => {
  const { t } = useTranslation();

  return <CardRoot key={key} onClick={()=>onClick(item)} sx={{
    ...(selected && {
      background: 'aliceblue',
    }),
  }}>
    <Grid container>
      <Grid item container spacing={2} xs={12}>
        <FeatureItem name={'Feature'} value={item.feature} width={4}/>
        <FeatureItem name={'Display Name'} value={item.feature_name} width={4}/>
        <FeatureItem name={'Data Type'} value={item.data_type} width={4}/>
        <FeatureItem name={'Block from PPSM'} value={item.block_from_ppsm?'Yes':'No'} width={4}/>
        <FeatureItem name={'Trend Operation'} value={item.trend_operation?item.trend_operation:'-'} width={4}/>
      </Grid>
    </Grid>
  </CardRoot>;
};

export default Feature;