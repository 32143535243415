import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import Typography from '@mui/material/Typography';

import useFeaturesConfiguration from 'hooks/datasets/use-dataset-configuration/feature-configs';
import useDownloadDatasetFeatures from 'hooks/datasets/use-dataset-download/features';
import AlertCard from 'components/elements/AlertCard';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import InfiniteLoaderList from 'components/elements/InfiniteLoadList';
import ErrorDialog from 'components/elements/ErrorDialog';

import FeatureFilters from './feature-filters';
import Feature from './feature';
import FeatureForm from './feature-form';

const FeatureConfigurationStep = ({ formRef, dataset, height, onSubmit, configs, handleOriginalConfigs }) => {
  const { t } = useTranslation();
  const { datasetId } = useParams();
  const [featuresConfigs, setFeaturesConfigs] = useState([]);
  const virtuoso = useRef(null);
  const inputRef = useRef();
  const [featuresUploaded, setFeaturesUploaded] = useState(false);
  const [search, setSearch] = useState('');
  const [errors, setErrors] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [currentFeature, setCurrentFeature] = useState(null);

  const { data, status, isFetchingNextPage, hasNextPage, fetchNextPage, isError } = useFeaturesConfiguration({
    datasetId,
    search
  });
  const { mutateAsync } = useDownloadDatasetFeatures();

  useEffect(() => {
    if (data) {
      const newData = data.pages.reduce((accum, curr) => {
        return [...accum, ...curr.data];
      }, []);

      if (!featuresUploaded) {
        handleOriginalConfigs({
          features: newData,
        });
      }

      if (!configs['features']) {
        const initialData = [...featuresConfigs];
        const ids = new Set(initialData.map(d => d.feature));
        const merged = [...initialData, ...newData.filter(d => !ids.has(d.feature))];
        setFeaturesConfigs(merged);
        if (!currentFeature) {
          setCurrentFeature(merged[0]);
        }
      } else {
        const initialData = [...configs['features']];
        const ids = new Set(initialData.map(d => d.feature));
        const merged = [...initialData, ...newData.filter(d => !ids.has(d.feature))];
        setFeaturesConfigs(merged);
        if (!currentFeature) {
          setCurrentFeature(merged[0]);
        }
      }
    }
  }, [data, configs]);

  useEffect(() => {
  }, [search]);

  const handleSelect = (feature) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
    setSelectedFeature(feature);
  };

  const handleDownload = () => {
    mutateAsync({ datasetId }).then((res) => {
      saveAs(res.data, res.filename);
    });
  };

  const handleUpload = () => {
    inputRef.current.click();
  };

  const checkIfKeyExist = (objectName, keys) => {
    return Object.keys(objectName).every(key => keys.includes(key));
  };

  const resetFileInput = () => {
    inputRef.current.value = '';
  };

  const processFile = (event) => {
    const fileUploaded = event.target.files[0];
    Papa.parse(fileUploaded, {
      header: true,
      skipEmptyLines: true,
      complete: function(results) {
        const featureIds = new Set(dataset['columns'].map(d => d.id));
        let features = [];
        let uploadErrors = [];
        for (const result of results.data) {
          const keyExist = checkIfKeyExist(result, ['feature', 'block_from_ppsm', 'feature_name', 'data_type', 'trend_operation']);
          if (!keyExist) {
            uploadErrors.push(`CSV must only contain column names feature, feature_name,data_type,block_from_ppsm and trend_operation.`);
            break;
          }

          if (!featureIds.has(result['feature'])) {
            uploadErrors.push(`${result['feature']} feature is not available in the uploaded csv.`);
          }

          if (!['NUMERICAL', 'BOOLEAN', 'CATEGORICAL', 'DATE'].includes(result['data_type'])) {
            uploadErrors.push(`Data type ${result['data_type']} of feature ${result['feature']} is invalid and should be one of NUMERICAL, BOOLEAN, CATEGORICAL or DATE`);
          }

          if (!['NUMERICAL', 'BOOLEAN'].includes(result['data_type']) && ['mean', 'sum'].includes(result['trend_operation'])) {
            uploadErrors.push(`Trend operation ${result['trend_operation']} for ${result['feature']} is invalid and only available for NUMERICAL or  BOOLEAN features`);
          }
          features.push({
            ...result,
            block_from_ppsm: result['block_from_ppsm'] === 'true',
            detected_data_type: result['data_type'],
          });
        }
        if (uploadErrors.length === 0) {
          setFeaturesUploaded(true);
          setFeaturesConfigs(features);
          setCurrentFeature(features[0]);
          handleOriginalConfigs({
            features: features,
          });
        } else {
          setErrors(uploadErrors);
        }
        resetFileInput();
      },
    });
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleErrorClose = () => {
    setErrors(null);
  };

  const handleSubmit = (values) => {
    if (values['submit']) {
      onSubmit({
        features: featuresConfigs.map(obj => obj.feature === values.feature ? {
          'feature': values.feature,
          'feature_name': values.feature_name,
          'data_type': values.data_type,
          'detected_data_type': values.detected_data_type,
          'block_from_ppsm': values.block_from_ppsm,
          'trend_operation': values.trend_operation,
        } : obj),
      });
    } else {
      setFeaturesConfigs(featuresConfigs.map(obj => obj.feature === values.feature ? values : obj));
      setCurrentFeature(selectedFeature);
    }
  };

  return (<>

      <Grid item xs={12} pt={1} sx={{
        maxHeight: `${height}px`,
        minHeight: `${height}px`,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}>
        <Grid container spacing={3}>
          <Grid item xs={12} container spacing={2}>
            <FeatureFilters handleSearch={handleSearch} />
            <Grid item container spacing={2} xs={6} justifyContent={'flex-end'}>
              <Grid item>
                <Button startIcon={<FileUploadIcon />}
                        size={'small'}
                        onClick={handleUpload}
                        variant={'outlined'}>
                  {t('Upload CSV')}
                </Button>
                <input ref={inputRef} accept='.csv' onChange={processFile} type={'file'}
                       hidden />
              </Grid>
              <Grid item>
                <Button startIcon={<DownloadIcon />}
                        size={'small'}
                        onClick={handleDownload}
                        variant={'outlined'}>
                  {t('Download features CSV')}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {(status === 'error') &&
            <AlertCard severity={'error'} height={400} message={'Something went wrong !'} />}

          {status === 'loading' ? <Grid container justifyContent='center' alignItems='center'
                                        sx={{ width: '100%', minHeight: 400 }}>
              <Grid item xs={12} container justifyContent='center' spacing={2}>
                <Grid item xs={12} container justifyContent='center'>
                  <LoaderSpinner type='Bars' color='#175da8' secondaryColor={'#6abed5'}
                                 height={70} width={70} />
                </Grid>
                <Grid item>
                  <Typography>Loading features</Typography>
                </Grid>
              </Grid>
            </Grid> :
            <Grid item xs={12} container justifyContent='center' spacing={2}>
              <Grid item xs={6}>
                <InfiniteLoaderList
                  height={height - 72}
                  data={featuresConfigs.filter(item => item.feature.toLowerCase().includes(search))}
                  ref={virtuoso}
                  isFetchingNextPage={isFetchingNextPage || search !== ''}
                  hasNextPage={hasNextPage}
                  fetchNextPage={fetchNextPage}
                  isError={isError}
                  itemTemplate={({ data, index }) => <Feature errors={errors}
                                                              key={index}
                                                              selected={currentFeature && currentFeature.feature === data.feature}
                                                              onClick={handleSelect}
                                                              item={data} />}
                />
              </Grid>

              <Grid item xs={6}>
                <>{currentFeature && <FeatureForm item={currentFeature} formRef={formRef} onSubmit={handleSubmit} />}</>
              </Grid>
            </Grid>
          }

        </Grid>
      </Grid>
      <ErrorDialog open={errors} title={'Failed to upload file.'} handleClose={handleErrorClose} />
    </>
  );
};

export default FeatureConfigurationStep;