import {useQuery} from '@tanstack/react-query';

import api from 'services/api';

const useAnalysisConfiguration = ({datasetId}) => {

    const fetchConfigurations = async () => {

        if (!datasetId) {
            return Promise.reject('Failed to query dataset. Dataset Id cannot be empty.');
        }

        try {
            const response = await api.get(`api/v1/datasets/${datasetId}/analysis-configs`);
            const {
                data: {
                    payload: {configs},
                    meta,
                },
            } = await response.json();

            return {data: configs, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useQuery(['analysis-configs-v1', datasetId], fetchConfigurations, {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        cacheTime: 0,
        staleTime: 0,
    });
};

export default useAnalysisConfiguration;