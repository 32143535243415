import {useInfiniteQuery} from '@tanstack/react-query';

import api from 'services/api';

const useFeaturesConfiguration = ({datasetId, search='', enabled = true}) => {

    const fetchConfigurations = async ({pageParam = 1}) => {

        const query = new URLSearchParams({
            page: pageParam
        });

        if (search) {
            query.append('feature', search);
        }

        if (!datasetId) {
            return Promise.reject('Failed to query dataset. Dataset Id cannot be empty.');
        }

        try {
            const response = await api.get(`api/v1/datasets/${datasetId}/features-configs?${query.toString()}`);
            const {
                data: {
                    payload: {features},
                    meta,
                },
            } = await response.json();

            return {data: features, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useInfiniteQuery(['features-configs-v1', datasetId, search], fetchConfigurations, {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
        cacheTime: 0,
        staleTime: 0,
        enabled,
        getNextPageParam: ({meta: {next_page}}, allPages) => {
            if (next_page === 0) return undefined;

            return next_page;
        },
    });
};

export default useFeaturesConfiguration;