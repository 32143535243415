import React, {useEffect, useState} from 'react';
import {Box, TextField} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import useDebounce from 'hooks/useDebounce';

const DebouncedSearchField = ({
                                  onSearch,
                                  placeholder = '',
                                  id = 'search-input',
                                  value = '',
                                  startIcon = true,
                                  type = 'text',
                                fontSize='1rem'
                              }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(() => {
        onSearch(searchTerm);
    }, [debouncedSearchTerm]);

    useEffect(() => {
        setSearchTerm(value);
    }, [value]);

    return (
        <Box>
            <TextField
                id={id}
                variant="outlined"
                fullWidth={true}
                placeholder={placeholder}
                size="small"
                type={type}
                sx={{
                    backgroundColor: '#fff',
                  "& .MuiInputBase-input": {
                    fontSize
                  },
                }}
                InputProps={{
                    startAdornment: startIcon && (
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                    ),
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
        </Box>
    );
};

export default DebouncedSearchField;
