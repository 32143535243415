import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, TextField } from '@mui/material';
import Switch from '@mui/material/Switch';
import { Formik, getIn } from 'formik';

import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';

const CardRoot = styled(Box)(() => ({
  padding: '24px 16px',
  border: '0.125em solid #E9E9E9',
  marginBottom: '4px',
  borderRadius:"4px",
  boxShadow: 'none',
  '&$expanded': {
    margin: '0px',
  },
}));

const EditableTextColumn = ({ initialValue, name, index, handleChange, touched, errors, label,disabled=false,required=false }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onBlur = () => {
    handleChange(`${name}`, value);
  };

  return <TextField id={`${name}`}
                    name={`${name}`}
                    variant='outlined'
                    label={label}
                    fullWidth
                    required={required}
                    value={value}
                    disabled={disabled}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={onBlur}
                    error={Boolean(getIn(touched, `${name}`) && getIn(errors, `${name}`))}
                    helperText={getIn(touched, `${name}`) && getIn(errors, `${name}`)}
                    size='small' />;
};

const EditableSelectColumn = ({
                                initialValue,
                                index,
                                handleChange,
                                touched,
                                errors,
                                options = [],
                                name,
                                showClear = false,
                                disabled = false,
                                label,
                              }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onBlur = () => {
    handleChange(`${name}`, value);
  };
  return <LabeledCustomSelect
    id={`${name}`}
    name={`${name}`}
    selectItems={options}
    showClear={showClear}
    disabled={disabled}
    onClear={(e) => handleChange(`${name}`, '')}
    value={value}
    onBlur={onBlur}
    onChange={(e) => {
      setValue(e.target.value);
    }}
    placeholder={label}
    label={label}
    error={Boolean(getIn(touched, `${name}`) && getIn(errors, `${name}`))}
    helperText={getIn(touched, `${name}`) && getIn(errors, `${name}`)}
    size='small'
  />;
};

const EditableSwitchColumn = ({ initialValue, name, index, handleChange, touched, errors, label }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onBlur = () => {
    handleChange(`${name}`, value);
  };

  return <FormControlLabel control={<Switch
    onChange={(e) => setValue(e.target.checked)}
    onBlur={onBlur}
    checked={Boolean(value)}
    name={`${name}`}
  />} label={label} labelPlacement='start' sx={{ marginLeft: '0 !important' }} />;
};

const FeatureForm = ({
                       item, formRef, key, onSubmit,
                     }) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    feature_name: Yup.string().required(t('feature.dataset.configure.features.feature_name.required')),
  });

  const dataTypes = [
    {
      name: 'Categorical',
      value: 'CATEGORICAL',
      id: 'CATEGORICAL',
    },
    {
      name: 'Numerical',
      value: 'NUMERICAL',
      id: 'NUMERICAL',
    },
    {
      name: 'Boolean',
      value: 'BOOLEAN',
      id: 'BOOLEAN',
    },
    {
      name: 'Date',
      value: 'DATE',
      id: 'DATE',
    },

  ];

  const trendOperations = [
    {
      id: 'MEAN',
      name: 'Mean',
      value: 'mean',
    },
    {
      id: 'SUM',
      name: 'Sum',
      value: 'sum',
    },
    {
      id: 'EXCLUDE',
      name: 'Exclude',
      value: '',
    },
  ];

  return <CardRoot key={key}>
    <Formik
      initialValues={item}
      validationSchema={validationSchema}
      innerRef={formRef}
      enableReinitialize={true}
      onSubmit={onSubmit}>
      {({
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (<Grid container>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize:'0.875rem',
                  fontWeight:500,
                  color: 'grey',
                }}>{'Feature'}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{
                  fontSize:'0.875rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>{values['feature']}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <EditableTextColumn
                initialValue={values['feature_name']}
                index={item['feature_name']}
                required
                name={'feature_name'}
                label={'Display Name'}
                handleChange={setFieldValue}
                touched={touched}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <EditableSelectColumn
                initialValue={values['data_type']}
                index={item['data_type']}
                name={'data_type'}
                label={'Data Type'}
                handleChange={setFieldValue}
                touched={touched}
                disabled={item['detected_data_type'] !== 'NUMERICAL'}
                errors={errors}
                options={dataTypes.filter(type => {
                  return !(item['detected_data_type'] === 'NUMERICAL' && ['BOOLEAN', 'DATE'].includes(type.value));
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <EditableSwitchColumn
                initialValue={values['block_from_ppsm']}
                name={'block_from_ppsm'}
                label={'Block From PPSM'}
                handleChange={setFieldValue}
              />
            </Grid>

            <Grid item xs={12}>
              <EditableSelectColumn
                initialValue={values['trend_operation']}
                name={'trend_operation'}
                label={'Trend Operation'}
                handleChange={setFieldValue}
                touched={touched}
                errors={errors}
                disabled={item['detected_data_type'] === 'CATEGORICAL' || item['detected_data_type'] === 'DATE'}
                options={trendOperations}
                showClear={true}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  </CardRoot>;
};

export default FeatureForm;