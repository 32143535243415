import React, {useEffect, useRef, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import * as Yup from 'yup';
import {FieldArray, Formik} from 'formik';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {saveAs} from 'file-saver';
import Papa from 'papaparse';

import useSegmentRulesConfiguration from 'hooks/datasets/use-dataset-configuration/segment-rules-configs';
import useDownloadDatasetSegmentRules from 'hooks/datasets/use-dataset-download/segment-rules';
import AlertCard from 'components/elements/AlertCard';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import ErrorDialog from 'components/elements/ErrorDialog';

import SegmentRuleCard from './segment-rule-card';
import Typography from "@mui/material/Typography";

const SegmentRulesConfigurationStep = ({formRef, dataset, height, onSubmit, configs, handleOriginalConfigs}) => {
    const {t} = useTranslation();
    const {datasetId} = useParams();
    const inputRef = useRef();
    const [segmentRulesUploaded, setSegmentRulesUploaded] = useState(false);
    const [segmentRulesConfigs, setSegmentRulesConfigs] = useState(null);
    const [errors, setErrors] = useState(null);

    const {data, status} = useSegmentRulesConfiguration({
        datasetId,
        enabled: !segmentRulesUploaded
    });
    const {mutateAsync} = useDownloadDatasetSegmentRules();

    const validationSchema = Yup.object().shape({
        segment_rules: Yup.array()
            .of(
                Yup.object().shape({
                    rule_name: Yup.string().required(t('Rule name is required')),
                    conditions: Yup.array()
                        .of(
                            Yup.object().shape({
                                variable: Yup.string().required(t('Variable is required')),
                                opd: Yup.mixed().required(t('Value is required')),
                                opr: Yup.string().required(t('Operation is required')),
                            })
                        ),
                })
            ),
    });

    useEffect(() => {
        if (data && configs && !configs['segment_rules']) {
            setSegmentRulesConfigs(data.data)
            handleOriginalConfigs({segment_rules: data.data})
        }
    }, [data])

    useEffect(() => {
        if (configs && configs['segment_rules']) {
            setSegmentRulesConfigs(configs['segment_rules'])
        }
    }, [configs])

    const handleDownload = () => {
        mutateAsync({datasetId}).then((res) => {
            saveAs(res.data, res.filename);
        });
    }

    const handleUpload = () => {
        inputRef.current.click();
    }

    const resetFileInput = () => {
        inputRef.current.value = "";
    }

    const processFile = (event) => {
        const fileUploaded = event.target.files[0];
        Papa.parse(fileUploaded, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                setSegmentRulesUploaded(true)
                const featureIds = new Set(dataset['columns'].map(d => d.id))
                const srList = [];
                let uploadErrors = []
                for (const element of results.data) {
                    const sr = {
                        rule_id: element['rule_id'],
                        rule_description: element['rule_description'],
                        rule_name: element['rule_name'],
                        conditions: []
                    }

                    for (let i = 1; i < 5; i++) {
                        if (element[`variable_${i}`] && element[`operation_${i}`]) {
                            if(!featureIds.has(element[`variable_${i}`])){
                                uploadErrors.push(`${element[`variable_${i}`]} feature is not available in the uploaded dataset.`)
                            }
                            sr.conditions.push(
                                {
                                    variable: element[`variable_${i}`],
                                    opd: element[`operand_${i}`],
                                    opr: element[`operation_${i}`]
                                }
                            )
                        }
                    }
                    srList.push(sr)
                }
                if (uploadErrors.length === 0) {
                    setSegmentRulesConfigs(srList)
                }else{
                    setErrors(uploadErrors);
                }
                resetFileInput()
            },
        });
    }

    const handleSubmit = (values) => {
        const req = {
            ...values,
            segment_rules: values['segment_rules'].map((sr, index) => {
                    return {
                        ...sr,
                        rule_id: index,
                    }
                }
            )
        }
        onSubmit(req)
    }

    const handleErrorClose = () => {
        setErrors(null)
    }

    if (status === 'error') {
        return <AlertCard severity={'error'} height={400} message={'Something went wrong !'}/>;
    }

    if (status === 'loading' || !segmentRulesConfigs) {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', minHeight: 400}}>
            <Grid item xs={12} container justifyContent="center" spacing={2}>
                <Grid item xs={12} container justifyContent="center">
                    <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
                </Grid>
                <Grid item>
                    <Typography>Loading segment rules</Typography>
                </Grid>
            </Grid>
        </Grid>
    }

    return (
        <>
            <Formik
                initialValues={{
                    segment_rules: segmentRulesConfigs
                }}
                validationSchema={validationSchema}
                innerRef={formRef}
                enableReinitialize={true}
                validateOnChange={false}
                onSubmit={handleSubmit}>
                {({
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue,
                  }) => (
                    <Grid item xs={12} container spacing={2}>
                        <FieldArray name="segment_rules">
                            {({insert, remove}) => (
                                <Grid item container spacing={2}>
                                    <Grid item xs={12} pb={2} container spacing={2} justifyContent={"flex-end"}>
                                        <Grid item>
                                            <Button startIcon={<FileUploadIcon/>}
                                                    size={'small'}
                                                    onClick={handleUpload}
                                                    variant={'outlined'}>
                                                {t('Upload CSV')}
                                            </Button>
                                            <input ref={inputRef} accept=".csv" onChange={processFile} type={'file'}
                                                   hidden/>
                                        </Grid>
                                        <Grid item>
                                            <Button startIcon={<DownloadIcon/>}
                                                    size={'small'}
                                                    onClick={handleDownload}
                                                    variant={'outlined'}>
                                                {t('Download segment rules CSV')}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button startIcon={<AddCircleOutlineIcon/>}
                                                    size={'small'}
                                                    onClick={() => {
                                                        insert(0, {
                                                            rule_id: 0,
                                                            rule_name: '',
                                                            rule_description: '',
                                                            conditions: [{
                                                                variable: '',
                                                                opd: '',
                                                                opr: ''
                                                            }]
                                                        })
                                                    }}
                                                    variant={'contained'}>
                                                {t('Add Rule')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{
                                        maxHeight: `${height - 104}px`,
                                        minHeight: `${height - 104}px`,
                                        overflowY: "auto",
                                        overflowX: "hidden"
                                    }}>
                                        {values['segment_rules'].map((field, index) => {
                                            return <SegmentRuleCard handleChange={setFieldValue}
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    index={index}
                                                                    dataset={dataset}
                                                                    removeRule={remove}
                                                                    duplicateRule={() => {
                                                                        insert(0, field)
                                                                    }}
                                                                    field={field}/>
                                        })}
                                    </Grid>
                                </Grid>
                            )}
                        </FieldArray>
                    </Grid>
                )}
            </Formik>
            <ErrorDialog open={errors} title={"Failed to upload file."} handleClose={handleErrorClose}/>
        </>
    );
};

export default SegmentRulesConfigurationStep;